import React, { useEffect, useRef, useState } from "react";

import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

// redux
import { register } from "store/User";

const SignupModal = ({ isModalOpen, closeModal, done, error, kundelikId = "", makeRegister, t }) => {
  const modalRef = useRef(null);

  // Закрытие по клику вне модального окна
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  // Закрытие по нажатию на Escape
  const handleEscape = (event) => {
    if (event.key === "Escape") {
      closeModal();
    }
  };

  // Добавляем обработчики событий при монтировании компонента
  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleEscape);
    }

    // Очистка обработчиков при размонтировании или закрытии модального окна
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscape);
    };
  }, [isModalOpen]);

  const [hasAggreed, setAggreed] = useState(false);
  const [isPasswordVisible, togglePasswordVisibility] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleRegister = (event) => {
    event.preventDefault();

    makeRegister(firstName, lastName, email, password, kundelikId).then(() => {
      console.log("finish");
    });
  };

  const isRegisterDisabled = !hasAggreed;

  return (
    <div className={`e-modal ${isModalOpen ? "e-show" : ""}`} data-modal="login">
      <div className="e-modal_card e-max-w-md" ref={modalRef}>
        <div className="e-modal_header e-bg-white e-flex e-items-center e-justify-between e-px-8 e-py-5 e-border-b e-sticky e-top-0 e-z-10">
          <h2 className="e-text-xl e-font-medium">{t("landing.registermodal.header")}</h2>
          <button className="e-modal_close e-btn e-btn-xs e-w-8 e-rounded-md e-px-2 e-btn-light" type="button" onClick={closeModal}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z" />
            </svg>
          </button>
        </div>
        <div className="e-modal_body e-px-8 e-py-7">
          <form className="e-flex e-flex-col" onSubmit={handleRegister}>
            {done && <div className="e-w-full e-mb-4 e-border e-border-yellow-500 e-bg-yellow-50 e-px-4 e-py-3 e-space-y-2 e-rounded-md e-text-yellow-900 e-text-sm landing-modal-form-message success">{t("landing.registermodal.msg.success")}</div>}
            {error && <div className="e-w-full e-mb-4 e-border e-border-red-500 e-bg-red-50 e-px-4 e-py-3 e-space-y-2 e-rounded-md e-text-red-900 e-text-sm landing-modal-form-message error">{t("landing.registermodal.msg.error")}</div>}

            <label className="e-relative e-mb-4">
              <input type="text" name="lastName" value={lastName} required placeholder={t("lastName") + "*"} onChange={(event) => setLastName(event.target.value)} autoComplete="off" className="e-peer e-w-full e-h-12 e-rounded-lg e-bg-white e-px-3 e-transition-all e-border-2 e-outline-none focus:e-border-primary" />
            </label>

            <label className="e-relative e-mb-4">
              <input type="text" name="firstName" value={firstName} required placeholder={t("firstName") + "*"} onChange={(event) => setFirstName(event.target.value)} autoComplete="off" className="e-peer e-w-full e-h-12 e-rounded-lg e-bg-white e-px-3 e-transition-all e-border-2 e-outline-none focus:e-border-primary" />
            </label>

            <label className="e-relative e-mb-4">
              <input type="email" name="email" value={email} required placeholder="Email*" onChange={(event) => setEmail(event.target.value)} autoComplete="off" className="e-peer e-w-full e-h-12 e-rounded-lg e-bg-white e-px-3 e-transition-all e-border-2 e-outline-none focus:e-border-primary" />
            </label>

            <label className="e-relative e-mb-4">
              <input name="password" value={password} required type={isPasswordVisible ? "text" : "password"} placeholder={t("password") + "*"} onChange={(event) => setPassword(event.target.value)} autoComplete="off" className="e-peer e-w-full e-h-12 e-rounded-lg e-bg-white e-px-3 e-transition-all e-border-2 e-outline-none focus:e-border-primary" />
            </label>

            {kundelikId && <input className="e-peer e-w-full e-h-12 e-rounded-lg e-bg-white e-px-3 e-transition-all e-border-2 e-outline-none focus:e-border-primary" disabled type="text" value={`Kundelik ID: ${kundelikId}`} />}

            <div className="e-flex e-items-center e-justify-between e-mt-2 e-mb-8 e-px-1">
              <label className="e-flex e-items-start e-cursor-pointer">
                <input name="isAgreed" value={hasAggreed} type="checkbox" onChange={(event) => setAggreed(event.target.checked)} className="e-peer e-hidden" />
                <span className="e-mr-2 e-h-[1.125rem] e-w-[1.125rem] e-border-2 e-flex e-flex-shrink-0 e-rounded e-transition peer-checked:e-bg-primary peer-checked:e-border-primary">
                  <svg className="e-text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor">
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path>
                  </svg>
                </span>
                <span className="e-select-none e-leading-tight">{t("landing.registermodal.agreement")}</span>
              </label>
            </div>

            <button type="submit" className="e-btn e-btn-primary" disabled={isRegisterDisabled}>
              {t("register")}
            </button>

            <div className="e-text-center e-text-sm e-text-gray-500 e-mt-4">{t("landing.registermodal.note")}</div>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  done: state.user.register.done,
  error: state.user.register.fail,
});

const mapDispatchToProps = (dispatch) => ({
  makeRegister: (firstName, lastName, email, phone, password, kundelikId) => dispatch(register(firstName, lastName, email, phone, password, kundelikId)),
});

export const WrappedSignupModal = compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(SignupModal);
