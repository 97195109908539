// modules
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

import LanguageSelect from "components/LanguageSelect";

import logo from '../../../assets/images/landing/logo.svg'

import { LoginModal, SignupModal } from '../Modals';

// redux
import { logout } from "../../../store/User";


// Компонент Header
const Header = ({ t }) => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isHidden, setIsHidden] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // Добавляем состояние для моб. меню
    const headerRef = useRef(null);
    const mobileMenuRef = useRef(null); // Реф для мобильного меню

    let prevScroll = window.pageYOffset;

    const handleScroll = () => {
        const currentScroll = window.pageYOffset;
        const isScrolledDown = currentScroll > prevScroll;

        if (currentScroll > 50 && isScrolledDown) {
            setIsHidden(true);  // скрыть header при прокрутке вниз
        } else {
            setIsHidden(false); // показать header при прокрутке вверх
        }

        setIsScrolled(currentScroll > 0); // добавить фон и тени при прокрутке
        prevScroll = currentScroll;
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Функция для открытия/закрытия мобильного меню
    const toggleMobileMenu = () => {
        setIsMobileMenuOpen((prev) => !prev);
    };

    const [isLoginModalOpen, setLoginModalOpen] = useState(false);
    const [isSignupModalOpen, setSignupModalOpen] = useState(false);

    const openLoginModal = () => setLoginModalOpen(true);
    const closeLoginModal = () => setLoginModalOpen(false);

    const openSignupModal = () => setSignupModalOpen(true);
    const closeSignupModal = () => setSignupModalOpen(false);

    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) =>
        Boolean(!!state.user.user.email)
    );


    return (
        <>

            <header
                ref={headerRef}
                className={`header e-h-20 sm:e-h-fit sm:e-py-2 e-flex e-items-center e-fixed e-inset-x-0 e-top-0 e-z-50 e-transition e-duration-300 ${isHidden ? '-e-translate-y-full' : ''
                    } ${isScrolled ? 'e-bg-white e-shadow' : ''}`}
            >
                <div className="e-container">
                    <div className="e-flex e-items-center sm:e-flex-col-reverse">
                        <div className="e-flex e-items-center e-justify-between e-w-full">
                            <a href="/" className="e-h-full e-flex e-items-center e-space-x-3">
                                <img src={logo} alt='ekitap.kz' />
                                <span className="e-text-secondary e-text-xl e-font-logo"> <span className="e-text-primary">ekitap</span>.kz </span>
                            </a>

                            <label className="e-hidden sm:e-flex e-flex-shrink-0 e-btn e-btn-light e-w-12 e-px-2 e-cursor-pointer">
                                <input id="mobmenu" type="checkbox" className="e-peer e-hidden" onClick={toggleMobileMenu} />
                                <span className="e-relative e-h-0.5 e-w-4 e-bg-black e-transition after:e-absolute after:e-h-0.5 after:e-w-2.5 after:e-bg-black after:e-left-0 after:-e-bottom-1.5 after:e-transition before:e-absolute before:e-h-0.5 before:e-w-4 before:e-bg-black before:e-left-0 before:-e-top-1.5 before:e-transition peer-checked:e-bg-transparent peer-checked:after:e-w-4 peer-checked:after:e-rotate-45 peer-checked:after:e-top-0 peer-checked:before:-e-rotate-45 peer-checked:before:e-top-0"> </span>
                            </label>
                        </div>

                        <div data-show={isMobileMenuOpen ? 'true' : 'false'} ref={mobileMenuRef} className="mobile-menu e-flex e-items-center e-space-x-2 e-ml-auto sm:e-ml-0 sm:e-w-full sm:e-border-b sm:e-space-x-4 sm:e-transition-all sm:data-[show=true]:e-mb-2 sm:data-[show=true]:e-pb-2 sm:data-[show=true]:e-visible sm:data-[show=true]:e-max-h-14 sm:data-[show=true]:e-translate-y-0 sm:e-invisible sm:e-max-h-0 sm:-e-translate-y-full">
                            {isAuthenticated ? (<button onClick={() => dispatch(logout())} type="button" className="e-btn e-duration-500 hover:e-bg-primary/5 sm:e-h-auto sm:e-bg-none sm:e-px-0">{t("logout")}</button>) : (
                                <>
                                    <button onClick={openLoginModal} type="button" className="e-btn e-duration-500 hover:e-bg-primary/5 sm:e-h-auto sm:e-bg-none sm:e-px-0">{t('landing.login')}</button>
                                    <button onClick={openSignupModal} type="button" className="e-btn e-btn-primary e-group sm:e-h-auto sm:e-bg-none sm:e-px-0 sm:e-text-inherit sm:!e-mr-auto">
                                        {t('landing.register')}
                                        <svg className="e-ml-1 e-transition group-hover:e-translate-x-1.5 sm:e-hidden" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path>
                                        </svg>
                                    </button>
                                </>
                            )}

                            <LanguageSelect />
                        </div>
                    </div>
                </div>
            </header>
            <LoginModal isModalOpen={isLoginModalOpen} closeModal={closeLoginModal} />
            <SignupModal isModalOpen={isSignupModalOpen} closeModal={closeSignupModal} />
        </>

    );
};

export default withTranslation()(Header);