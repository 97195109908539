// modules
import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from "react-i18next";

import { useColor } from '../Utils/ColorContext';

function Preloader() {
    return (
        <div className="Preloader">
            <header className="e-h-20 sm:e-h-fit sm:e-py-2 e-flex e-items-center e-fixed e-inset-x-0 e-top-0 e-z-50 e-transition e-duration-300">
                <div className="e-container">
                    <div className="e-flex e-items-center sm:e-flex-col-reverse">
                        <div className="e-flex e-items-center e-justify-between e-w-full">
                            <div className="e-h-full e-flex e-items-center e-space-x-3">
                                <div className="e-w-12 e-h-12 e-bg-secondary/20 e-flex e-flex-shrink-0 e-rounded-full e-animate-pulse"></div>
                                <span className="e-h-6 e-w-28 e-bg-secondary/20 e-flex e-flex-shrink-0 e-rounded-full e-animate-pulse"></span>
                            </div>

                            <div className="e-hidden sm:e-flex e-w-12 e-h-12 e-bg-secondary/20 e-flex-shrink-0 e-rounded-full e-animate-pulse"></div>
                        </div>

                        <div data-show="false" className="e-flex e-items-center e-space-x-2 e-ml-auto sm:e-ml-0 sm:e-w-full sm:e-border-b sm:e-space-x-4 sm:e-transition-all sm:data-[show=true]:e-mb-2 sm:data-[show=true]:e-pb-2 sm:data-[show=true]:e-visible sm:data-[show=true]:e-max-h-14 sm:data-[show=true]:e-translate-y-0 sm:e-invisible sm:e-max-h-0 sm:e--translate-y-full">
                            <div className="e-w-20 e-h-6 e-bg-secondary/20 e-flex e-flex-shrink-0 e-rounded-full e-animate-pulse"></div>
                            <div className="e-w-36 e-h-12 e-bg-secondary/20 e-flex e-flex-shrink-0 e-rounded-full e-animate-pulse"></div>
                            <div className="e-w-12 e-h-12 e-bg-secondary/20 e-flex e-flex-shrink-0 e-rounded-full e-animate-pulse"></div>
                        </div>
                    </div>
                </div>
            </header>

            <main>
                <section className="e-bg-custom-gradient e-w-full e-relative e-graph-paper">
                    <div className="e-container e-h-screen">
                        <div className="e-flex md:e-flex-col e-h-full e-pt-36 e-pb-16 e-overflow-hidden e-relative">
                            <div className="e-flex e-flex-col e-justify-center e-space-y-6 md:e-z-10 e-w-full">
                                <div className="e-flex e-flex-col e-space-y-3">
                                    <div className="e-w-48 e-h-10 e-bg-secondary/20 e-flex e-flex-shrink-0 e-rounded-full e-animate-pulse"></div>
                                    <div className="e-w-80 e-h-10 e-bg-secondary/20 e-flex e-flex-shrink-0 e-rounded-full e-animate-pulse"></div>
                                    <div className="e-w-56 e-h-10 e-bg-secondary/20 e-flex e-flex-shrink-0 e-rounded-full e-animate-pulse"></div>
                                </div>
                                <div className="e-flex e-flex-col e-space-y-3">
                                    <div className="e-w-5/6 e-h-6 e-bg-secondary/20 e-flex e-flex-shrink-0 e-rounded-full e-animate-pulse"></div>
                                    <div className="e-w-20 e-h-6 e-bg-secondary/20 e-flex e-flex-shrink-0 e-rounded-full e-animate-pulse"></div>
                                </div>

                                <div className="e-flex e-items-center e-space-x-6 e-!mb-4">
                                    <div className="e-w-44 e-h-14 e-bg-secondary/20 e-flex e-flex-shrink-0 e-rounded-full e-animate-pulse"></div>
                                    <div className="e-w-14 e-h-14 e-bg-secondary/20 e-flex e-flex-shrink-0 e-rounded-full e-animate-pulse"></div>
                                </div>

                                <div className="e-flex e-flex-wrap e-gap-x-6 e-gap-y-2">
                                    <div className="e-flex e-space-x-3">
                                        <div className="e-w-5 e-h-5 e-bg-secondary/20 e-flex e-flex-shrink-0 e-rounded-full e-animate-pulse"></div>
                                        <div className="e-w-48 e-h-5 e-bg-secondary/20 e-flex e-flex-shrink-0 e-rounded-full e-animate-pulse"></div>
                                    </div>
                                    <div className="e-flex e-space-x-3">
                                        <div className="e-w-5 e-h-5 e-bg-secondary/20 e-flex e-flex-shrink-0 e-rounded-full e-animate-pulse"></div>
                                        <div className="e-w-48 e-h-5 e-bg-secondary/20 e-flex e-flex-shrink-0 e-rounded-full e-animate-pulse"></div>
                                    </div>
                                    <div className="e-flex e-space-x-3">
                                        <div className="e-w-5 e-h-5 e-bg-secondary/20 e-flex e-flex-shrink-0 e-rounded-full e-animate-pulse"></div>
                                        <div className="e-w-48 e-h-5 e-bg-secondary/20 e-flex e-flex-shrink-0 e-rounded-full e-animate-pulse"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="e-flex e-items-center e-w-1/3 e-justify-center md:e-absolute md:e-right-0 md:e-bottom-0 md:e-scale-50 md:e-origin-bottom-right sm:e-scale-35">
                                <div className="e-relative e-text-secondary/20 e-animate-pulse">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="120" height="120" fill="currentColor">
                                        <path fill="none" d="M0 0h24v24H0z"></path>
                                        <path d="M16 21L11.2381 12.2698L15 6L23 21H16ZM8 10L14 21H2L8 10ZM5.5 8C4.11929 8 3 6.88071 3 5.5C3 4.11929 4.11929 3 5.5 3C6.88071 3 8 4.11929 8 5.5C8 6.88071 6.88071 8 5.5 8Z"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
}

export default Preloader;