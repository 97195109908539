import React, { useEffect, useRef, useState } from "react";

import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Trans, withTranslation } from "react-i18next";

// assets
import kundelikLogo from "assets/images/kundelik-logo.png";

// redux
import { forgotPassword, login } from "store/User";
// utils
import kundelikIntegration from "../../../services/kundelik-integration";
import { userRoles } from "../../../assets/utils";

const KundelikIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
    <path fill="none" d="M0 0h24v24H0z"></path>
    <path d="M5.23379 7.72989C6.65303 5.48625 9.15342 4 12.0002 4C14.847 4 17.3474 5.48625 18.7667 7.72989L20.4569 6.66071C18.6865 3.86199 15.5612 2 12.0002 2C8.43928 2 5.31393 3.86199 3.54356 6.66071L5.23379 7.72989ZM12.0002 20C9.15342 20 6.65303 18.5138 5.23379 16.2701L3.54356 17.3393C5.31393 20.138 8.43928 22 12.0002 22C15.5612 22 18.6865 20.138 20.4569 17.3393L18.7667 16.2701C17.3474 18.5138 14.847 20 12.0002 20ZM12 8C12.5523 8 13 8.44772 13 9C13 9.55228 12.5523 10 12 10C11.4477 10 11 9.55228 11 9C11 8.44772 11.4477 8 12 8ZM12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12ZM12 15C10.8954 15 10 15.8954 10 17H8C8 14.7909 9.79086 13 12 13C14.2091 13 16 14.7909 16 17H14C14 15.8954 13.1046 15 12 15ZM3 11C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13C3.55228 13 4 12.5523 4 12C4 11.4477 3.55228 11 3 11ZM0 12C0 10.3431 1.34315 9 3 9C4.65685 9 6 10.3431 6 12C6 13.6569 4.65685 15 3 15C1.34315 15 0 13.6569 0 12ZM20 12C20 11.4477 20.4477 11 21 11C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13C20.4477 13 20 12.5523 20 12ZM21 9C19.3431 9 18 10.3431 18 12C18 13.6569 19.3431 15 21 15C22.6569 15 24 13.6569 24 12C24 10.3431 22.6569 9 21 9Z"></path>
  </svg>
);
const MailIcon = () => (
  <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
    <path d="M20 22H18V20C18 18.3431 16.6569 17 15 17H9C7.34315 17 6 18.3431 6 20V22H4V20C4 17.2386 6.23858 15 9 15H15C17.7614 15 20 17.2386 20 20V22ZM12 13C8.68629 13 6 10.3137 6 7C6 3.68629 8.68629 1 12 1C15.3137 1 18 3.68629 18 7C18 10.3137 15.3137 13 12 13ZM12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"></path>
  </svg>
);
const PasswordIcon = () => (
  <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
    <path d="M12.917 13C12.441 15.8377 9.973 18 7 18C3.68629 18 1 15.3137 1 12C1 8.68629 3.68629 6 7 6C9.973 6 12.441 8.16229 12.917 11H23V13H21V17H19V13H17V17H15V13H12.917ZM7 16C9.20914 16 11 14.2091 11 12C11 9.79086 9.20914 8 7 8C4.79086 8 3 9.79086 3 12C3 14.2091 4.79086 16 7 16Z"></path>
  </svg>
);
const ShowPasswordIcon = () => (
  <svg className="e-text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor">
    <path fill="none" d="M0 0h24v24H0z"></path>
    <path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"></path>
  </svg>
);

const LoginModal = ({ isModalOpen, closeModal, forgotPasswordDone, forgotPasswordError, history, kundelikId = "", loginDone, loginStatus, makeForgotPassword, makeLogin, role, t }) => {
  const modalRef = useRef(null);

  // Закрытие по клику вне модального окна
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  // Закрытие по нажатию на Escape
  const handleEscape = (event) => {
    if (event.key === "Escape") {
      closeModal();
    }
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, togglePasswordVisibility] = useState(false);
  const [isForgotPasswordShown, toggleForgotPassword] = useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = useState("");
  const [forgotPasswordMsg, setForgotPasswordMsg] = useState("");

  // Добавляем обработчики событий при монтировании компонента
  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleEscape);
    }

    // Очистка обработчиков при размонтировании или закрытии модального окна
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscape);
    };
  }, [isModalOpen]);

  useEffect(() => {
    if (loginDone && role) {
      const redirectUrl = userRoles.includes(role) ? "/dashboard/courses" : "/admin";
      history.push(redirectUrl);
    }
  }, [loginDone, role]);

  useEffect(() => {
    if (loginStatus) {
      switch (loginStatus) {
        case 401:
          setLoginErrorMsg(t("errors.401"));
          break;
        case 403:
          setLoginErrorMsg(t("errors.403"));
          break;
        case 404:
          setLoginErrorMsg(t("errors.404"));
          break;
        case 503:
          setLoginErrorMsg(t("errors.503"));
          break;
        default:
          setLoginErrorMsg(t("errors.default"));
      }
    }
  }, [loginStatus]);

  useEffect(() => {
    if (forgotPasswordDone) {
      setForgotPasswordMsg(<div className="auth-modal-success-msg">{t("landing.forgotmodal.msg.success")}</div>);
    }
  }, [forgotPasswordDone]);

  useEffect(() => {
    if (forgotPasswordError) {
      setForgotPasswordMsg(<div className="auth-modal-error-msg">{t("landing.forgotmodal.msg.error")}</div>);
    }
  }, [forgotPasswordError]);

  const handleForgot = (event) => {
    event.preventDefault();
    makeForgotPassword(email).catch((err) => console.error(err));
  };

  const handleLogin = (event) => {
    event.preventDefault();
    makeLogin(email, password, kundelikId).catch((err) => console.error(err));
  };

  const handleKundelikLogin = () => {
    kundelikIntegration.authenticate();
  };

  return (
    <div className={`e-modal ${isModalOpen ? "e-show" : ""}`} data-modal="login">
      <div className="e-modal_card e-max-w-md" ref={modalRef}>
        <div className="e-modal_header e-bg-white e-flex e-items-center e-justify-between e-px-8 e-py-5 e-border-b e-sticky e-top-0 e-z-10">
          <h2 className="e-text-xl e-font-medium">{isForgotPasswordShown ? <>{t("landing.forgotmodal.header")}</> : <>{t("landing.loginmodal.header")}</>}</h2>
          <button className="e-modal_close e-btn e-btn-xs e-w-8 e-rounded-md e-px-2 e-btn-light" type="button" onClick={closeModal}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z" />
            </svg>
          </button>
        </div>
        <div className="e-modal_body e-px-8 e-py-7">
          {isForgotPasswordShown ? (
            <form onSubmit={handleForgot} className="e-flex e-flex-col">
              <div class="e-mb-4 e-border e-border-yellow-500 e-bg-yellow-50 e-px-4 e-py-3 e-space-y-2 e-rounded-md e-text-yellow-900 e-text-sm">
                <div className="landing-modal-form-note">{t("landing.forgotmodal.text1")}</div>
                <div className="landing-modal-form-note">{t("landing.forgotmodal.text2")}</div>
              </div>

              {forgotPasswordMsg && <div className="e-mb-4 e-border e-border-red-500 e-bg-red-50 e-px-4 e-py-3 e-space-y-2 e-rounded-md e-text-red-900 e-text-sm landing-modal-message error">{forgotPasswordMsg}</div>}

              <label className="e-relative e-mb-4">
                <input type="text" autocomplete="off" name="email" value={email} required onChange={(event) => setEmail(event.target.value)} className="e-peer e-w-full e-h-12 e-rounded-lg e-bg-white e-pl-12 e-pr-3 e-transition-all e-border-2 e-outline-none focus:e-border-primary" placeholder="Email" />
                <span className="e-transition e-text-gray-400 peer-focus:e-text-primary e-absolute e-top-1/2 -e-translate-y-1/2 e-left-0 e-flex e-items-center e-justify-center e-w-12 e-h-12 e-flex-shrink-0">
                  <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path d="M20 22H18V20C18 18.3431 16.6569 17 15 17H9C7.34315 17 6 18.3431 6 20V22H4V20C4 17.2386 6.23858 15 9 15H15C17.7614 15 20 17.2386 20 20V22ZM12 13C8.68629 13 6 10.3137 6 7C6 3.68629 8.68629 1 12 1C15.3137 1 18 3.68629 18 7C18 10.3137 15.3137 13 12 13ZM12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"></path>
                  </svg>
                </span>
              </label>

              <hr className="e-mb-4" />

              <div className="e-flex e-items-center e-space-x-4">
                <button type="button" className="e-btn e-btn-light e-w-full" onClick={() => toggleForgotPassword(false)}>
                  <span>{t("landing.forgotmodal.login")}</span>
                </button>

                <button type="submit" className="e-btn e-btn-primary e-w-full">
                  {t("send")}
                </button>
              </div>
            </form>
          ) : (
            <form onSubmit={handleLogin} className="e-flex e-flex-col">
              {loginErrorMsg && <div className="e-mb-4 e-border e-border-red-500 e-bg-red-50 e-px-4 e-py-3 e-space-y-2 e-rounded-md e-text-red-900 e-text-sm landing-modal-message error">{loginErrorMsg}</div>}

              {kundelikId && (
                <label className="e-relative e-mb-4">
                  <input disabled type="text" id="kundelikId" name="kundelikId" placeholder="Kundelik ID" value={`Kundelik ID: ${kundelikId}`} className="e-peer e-w-full e-h-12 e-rounded-lg e-bg-white e-pl-12 e-pr-3 e-transition-all e-border-2 e-outline-none focus:e-border-primary" />
                  <span className="e-transition e-text-gray-400 peer-focus:e-text-primary e-absolute e-top-1/2 -e-translate-y-1/2 e-left-0 e-flex e-items-center e-justify-center e-w-12 e-h-12 e-flex-shrink-0">
                    <KundelikIcon />
                  </span>
                </label>
              )}

              <label className="e-relative e-mb-4">
                <input type="text" autoComplete="off" name="email" value={email} required onChange={(event) => setEmail(event.target.value)} className="e-peer e-w-full e-h-12 e-rounded-lg e-bg-white e-pl-12 e-pr-3 e-transition-all e-border-2 e-outline-none focus:e-border-primary" placeholder="Логин" />
                <span className="e-transition e-text-gray-400 peer-focus:e-text-primary e-absolute e-top-1/2 -e-translate-y-1/2 e-left-0 e-flex e-items-center e-justify-center e-w-12 e-h-12 e-flex-shrink-0">
                  <MailIcon />
                </span>
              </label>

              <label className="e-relative e-mb-4">
                <input type={isPasswordVisible ? "text" : "password"} autoComplete="off" name="password" value={password} required onChange={(event) => setPassword(event.target.value)} className="e-peer e-w-full e-h-12 e-rounded-lg e-bg-white e-pl-12 e-pr-3 e-transition-all e-border-2 e-outline-none focus:e-border-primary" placeholder={t("password")} />
                <span className="e-transition e-text-gray-400 peer-focus:e-text-primary e-absolute e-top-1/2 -e-translate-y-1/2 e-left-0 e-flex e-items-center e-justify-center e-w-12 e-h-12 e-flex-shrink-0">
                  <PasswordIcon />
                </span>
              </label>

              <div className="e-flex e-items-center e-justify-between e-mt-2 e-mb-8 e-px-1 e-text-sm">
                <label className="e-flex e-items-center e-cursor-pointer">
                  <input type="checkbox" name="isPasswordVisible" value={isPasswordVisible} onChange={(event) => togglePasswordVisibility(event.target.checked)} className="e-peer e-hidden" />
                  <span className="e-mr-2 e-h-[1.125rem] e-w-[1.125rem] e-border-2 e-flex e-flex-shrink-0 e-rounded e-transition peer-checked:e-bg-primary peer-checked:e-border-primary">
                    <ShowPasswordIcon />
                  </span>
                  <span className="e-select-none">{t("landing.registermodal.showpassword")}</span>
                </label>

                <span onClick={() => toggleForgotPassword(true)} className="e-cursor-pointer e-leading-none hover:e-text-primary">
                  {t("landing.loginmodal.forgot")}
                </span>
              </div>

              <button type="submit" className="e-btn e-btn-primary">
                {t("landing.login")}
              </button>

              <div className="e-my-4 e-flex e-items-center before:e-mt-0.5 before:e-flex-1 before:e-border-t before:e-border-gray-200 after:e-mt-0.5 after:e-flex-1 after:e-border-t after:e-border-gray-200">
                <p className="e-mx-4 e-mb-0 e-text-center e-text-sm e-font-medium e-text-gray-500">{t("landing.loginmodal.or")}</p>
              </div>

              <div className="e-btn e-btn-light e-cursor-pointer e-py-1 -e-text-indent-9999" onClick={handleKundelikLogin}>
                <Trans i18nKey="landing.loginWith" components={[<img className="e-h-full e-object-cover e-ml-1" src={kundelikLogo} alt="" />]} />
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  role: state.user.user.scope,
  forgotPasswordDone: state.user.forgotPassword.done,
  forgotPasswordError: state.user.forgotPassword.fail,
  loginDone: state.user.login.done,
  loginError: state.user.login.fail,
  loginStatus: state.user.login.status,
});

const mapDispatchToProps = (dispatch) => ({
  makeForgotPassword: (email) => dispatch(forgotPassword(email)),
  makeLogin: (email, password, kundelikId) => dispatch(login(email, password, kundelikId)),
});

export const WrappedLoginModal = compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(withRouter(LoginModal));
