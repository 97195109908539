import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from 'react-html-parser';

// utils
import { userRoles } from "../../../assets/utils";
import kundelikIntegration from '../../../services/kundelik-integration';

// redux
import { loginWithKundelik, logout } from "../../../store/User";


// assets
import girl from '../../../assets/images/landing/girl.png'
import books from '../../../assets/images/landing/books.png'
import laptop from '../../../assets/images/landing/laptop.jpeg'
import happy from '../../../assets/images/landing/happy.jpeg'
import medal from '../../../assets/images/landing/medal.jpeg'
import ai from '../../../assets/images/landing/ai.png'
import womanTablet from '../../../assets/images/landing/woman-tablet.png'

import { LoginModal, VideoModal } from '../Modals';
import CallBackForm from './CallBackForm';
import Reviews from './Reviews';
import Questions from './Questions';

import { useHistory } from 'react-router-dom';

const WelcomePage = ({ t }) => {
    const magneticButtonsRef = useRef([]);
    const cardsRef = useRef([]);

    const [isVideoModalOpen, setVideoModalOpen] = useState(false);
    const [isLoginModalOpen, setLoginModalOpen] = useState(false);

    const openVideoModal = () => setVideoModalOpen(true);
    const closeVideoModal = () => setVideoModalOpen(false);

    const openLoginModal = () => setLoginModalOpen(true);
    const closeLoginModal = () => setLoginModalOpen(false);

    useEffect(() => {
        // Очистка массивов перед повторным рендером

        if (magneticButtonsRef.current.length || cardsRef.current.length) {
            // Magnetic button effects
            magneticButtonsRef.current.forEach(button => {
                if (button) { // Проверка на null
                    button.addEventListener('mousemove', handleMouseMoveMagnetic);
                    button.addEventListener('mouseleave', handleMouseLeaveMagnetic);
                }
            });

            // Card effects
            cardsRef.current.forEach(card => {
                if (card) { // Проверка на null
                    card.addEventListener('mousemove', handleMouseMoveCard);
                    card.addEventListener('mouseleave', handleMouseLeaveCard);
                }
            });

            return () => {
                magneticButtonsRef.current.forEach(button => {
                    if (button) { // Проверка на null
                        button.removeEventListener('mousemove', handleMouseMoveMagnetic);
                        button.removeEventListener('mouseleave', handleMouseLeaveMagnetic);
                    }
                });

                cardsRef.current.forEach(card => {
                    if (card) { // Проверка на null
                        card.removeEventListener('mousemove', handleMouseMoveCard);
                        card.removeEventListener('mouseleave', handleMouseLeaveCard);
                    }
                });
            };
        }
    }, []);

    const handleMouseMoveMagnetic = (e) => {
        const btn = e.currentTarget.querySelector('.e-btn');
        const rect = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - rect.left - rect.width / 2;
        const y = e.clientY - rect.top - rect.height / 2;

        btn.style.transform = `translate(${x * 0.5}px, ${y * 0.5}px)`;
    };

    const handleMouseLeaveMagnetic = (e) => {
        const btn = e.currentTarget.querySelector('.e-btn');
        btn.style.transform = 'translate(0, 0)';
    };

    const handleMouseMoveCard = (e) => {
        const card = e.currentTarget;
        const rect = card.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        const rotateY = (x / rect.width - 0.5) * 30;
        const rotateX = -(y / rect.height - 0.5) * 30;

        card.querySelector('.e-card-content').style.transition = 'none';
        card.querySelector('.e-card-content').style.transform = `rotateY(${rotateY}deg) rotateX(${rotateX}deg)`;
    };

    const handleMouseLeaveCard = (e) => {
        const card = e.currentTarget;
        card.querySelector('.e-card-content').style.transition = 'transform 0.3s ease-in-out';
        card.querySelector('.e-card-content').style.transform = 'rotateY(0) rotateX(0)';
    };

    const dispatch = useDispatch();

    const isAuthenticated = useSelector((state) =>
        Boolean(!!state.user.user.email)
    );

    const role = useSelector((state) => state.user.user.scope);

    const history = useHistory();

    const [kundelikId, setKundelikId] = useState('');

    const loginWithKundelikDone = useSelector((state) => state.user.loginWithKundelik.done);

    useEffect(() => {
        const checkKundelikTokenInUrl = async () => {
            const { hash } = history.location;

            // check if access_token exists in URL string
            if (hash.length > 0) {
                const hashParams = new URLSearchParams(hash.substring(1));
                if (hashParams.has('access_token')) {
                    const token = hashParams.get('access_token');
                    const user = await kundelikIntegration.getCurrentUser(token);

                    if (user) {
                        const doesUserHaveKundelikId = await kundelikIntegration.checkEkitapUserForKundelikId(user.id_str);

                        if (doesUserHaveKundelikId) {
                            localStorage.setItem('kundelik-token', token);
                            dispatch(loginWithKundelik(user.id_str, token));
                        } else {
                            setKundelikId(user.id_str);
                            setLoginModalOpen(true);
                            // setRegistrationModalOpen(true);
                        }

                        window.history.replaceState(null, '', window.location.origin);
                    }
                }
            }
        }

        const checkHomeworkContextInUrl = () => {
            const { search } = history.location;

            // check if homework assigning context is given
            const searchParams = new URLSearchParams(search);
            if (searchParams.has('external_login', 'kundelik')) {
                // store Kundelik homework context in session storage
                const homeworkContext = {
                    group_id: searchParams.get('group_id'),
                    subject_id: searchParams.get('subject_id'),
                    lesson_id: searchParams.get('lesson_id'),
                    worktype_id: searchParams.get('worktype_id'),
                };
                sessionStorage.setItem('homework-context', JSON.stringify(homeworkContext));

                // check if teacher is authenticated with Kundelik
                kundelikIntegration.checkAuthentication();
            }
        }

        checkKundelikTokenInUrl();
        checkHomeworkContextInUrl();
    }, []);

    useEffect(() => {
        if (loginWithKundelikDone && role) {
            const redirectUrl = userRoles.includes(role) ? '/dashboard/courses' : '/admin';
            history.push(redirectUrl);
        }
    }, [loginWithKundelikDone, role]);

    return (

        <main>
            <section className="e-bg-custom-gradient e-w-full e-relative e-graph-paper e-mb-40">
                <div className="e-container">
                    <div className="e-flex md:e-flex-col e-h-full e-pt-36 e-pb-16 e-overflow-hidden e-relative">
                        <div className="e-flex e-flex-col e-justify-center e-space-y-6 md:e-z-10 e-w-full">
                            <h1 className="e-text-6xl md:e-text-5xl e-font-semibold">{ReactHtmlParser(t('landing.header'))}</h1>
                            <div className="e-text-lg e-text-gray-500 e-font-medium">{t('landing.text')}</div>

                            <div className="e-flex e-items-center e-space-x-6 !e-mb-4">
                                {isAuthenticated ? (
                                    <>
                                        <button onClick={() =>
                                            history.push(
                                                userRoles.includes(role) ? "/dashboard/courses" : "/admin"
                                            )
                                        }
                                            className="e-btn e-btn-lg e-btn-primary e-px-10">{userRoles.includes(role) ? t("landing.courseList") : t("landing.admin")}</button>
                                        <div className="e-magnetic-button" ref={el => magneticButtonsRef.current.push(el)}>
                                            <button onClick={() => dispatch(logout())} className="e-btn e-btn-lg e-btn-secondary e-w-14 e-px-2 e-flex-shrink-0">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor"><path fill="none" d="M0 0h24v24H0z"></path><path d="M5 22C4.44772 22 4 21.5523 4 21V3C4 2.44772 4.44772 2 5 2H19C19.5523 2 20 2.44772 20 3V6H18V4H6V20H18V18H20V21C20 21.5523 19.5523 22 19 22H5ZM18 16V13H11V11H18V8L23 12L18 16Z"></path></svg>
                                            </button>
                                        </div>
                                    </>
                                ) : (<>
                                    <button onClick={openLoginModal} className="e-btn e-btn-lg e-btn-primary e-px-10">{t('landing.startLearning')}</button>
                                    <div className="e-magnetic-button" ref={el => magneticButtonsRef.current.push(el)}>
                                        <button onClick={openVideoModal} className="e-btn e-btn-lg e-btn-secondary e-w-14 e-px-2 e-flex-shrink-0">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
                                                <path fill="none" d="M0 0h24v24H0z"></path>
                                                <path d="M6 20.1957V3.80421C6 3.01878 6.86395 2.53993 7.53 2.95621L20.6432 11.152C21.2699 11.5436 21.2699 12.4563 20.6432 12.848L7.53 21.0437C6.86395 21.46 6 20.9812 6 20.1957Z"></path>
                                            </svg>
                                        </button>
                                    </div>
                                </>)}
                            </div>

                            <ul className="e-flex e-flex-wrap e-text-lg e-font-medium md:e-pr-20 e-gap-x-6 e-gap-y-2 e-relative [&>li]:e-whitespace-nowrap [&>li]:e-flex [&>li]:before:e-mr-2.5 [&>li]:before:e-bg-[url(../images/checkmark.svg)] [&>li]:before:e-inline-block [&>li]:before:e-h-6 [&>li]:before:e-w-6">
                                {ReactHtmlParser(t('landing.advantages'))}
                            </ul>
                        </div>
                        <div className="e-flex e-items-center e-justify-center md:e-absolute md:e-right-0 md:e-bottom-0 md:e-scale-50 md:e-origin-bottom-right">
                            <img className="e-z-10" src={girl} draggable="false" />
                            {/* <img className="lg:e-hidden" src={books} draggable="false" /> */}
                        </div>
                    </div>

                    <CallBackForm />

                    <div className="e-flex e-flex-col e-space-y-10">
                        <div className='e-flex e-flex-col e-space-y-2'>
                            <h2 className="e-text-5xl md:e-text-4xl e-font-semibold e-text-center">{t('landing.forstudents.title')}</h2>
                            <div className="e-text-lg e-text-gray-500 e-font-medium e-text-center">{t('landing.forstudents.subtitle')}</div>
                        </div>
                        <div className="e-grid e-grid-cols-4 lg:e-grid-cols-2 md:e-gap-4 sm:e-grid-cols-1 sm:e-gap-6 e-gap-6">
                            <div className="e-card" ref={el => cardsRef.current.push(el)}>
                                <div className="e-card-content e-h-full e-bg-gradient-sky e-rounded-3xl e-shadow-[0_15px_20px_-25px_rgba(0,0,0,.35)] e-px-8 e-py-8">
                                    <div className="e-flex e-flex-col e-justify-center e-items-center e-space-y-3 e-text-center">
                                        <img className="e-w-28 e-h-20 e-drop-shadow e-object-cover e-rounded-full e-overflow-hidden" src={laptop} draggable="false" />
                                        <h3 className="e-text-2xl e-font-semibold">{t('landing.forstudents.card1.title')}</h3>
                                        <div className="e-text-lg e-text-gray-500">{t('landing.forstudents.card1.text')}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="e-card" ref={el => cardsRef.current.push(el)}>
                                <div className="e-card-content e-h-full e-bg-gradient-amber e-rounded-3xl e-shadow-[0_15px_20px_-25px_rgba(0,0,0,.35)] e-px-7 e-py-8">
                                    <div className="e-flex e-flex-col e-justify-center e-items-center e-space-y-3 e-text-center">
                                        <img className="e-w-28 e-h-20 e-drop-shadow e-object-cover e-rounded-full e-overflow-hidden" src={happy} draggable="false" />
                                        <h3 className="e-text-2xl e-font-semibold">{t('landing.forstudents.card2.title')}</h3>
                                        <div className="e-text-lg e-text-gray-500">{t('landing.forstudents.card2.text')}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="e-card" ref={el => cardsRef.current.push(el)}>
                                <div className="e-card-content e-h-full e-bg-gradient-purple e-rounded-3xl e-shadow-[0_15px_20px_-25px_rgba(0,0,0,.35)] e-px-4 e-py-8">
                                    <div className="e-flex e-flex-col e-justify-center e-items-center e-space-y-3 e-text-center">
                                        <img className="e-w-28 e-h-20 e-drop-shadow e-object-cover e-rounded-full e-overflow-hidden" src={medal} draggable="false" />
                                        <h3 className="e-text-2xl e-font-semibold">{t('landing.forstudents.card3.title')}</h3>
                                        <div className="e-text-lg e-text-gray-500">{t('landing.forstudents.card3.text')}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="e-card" ref={el => cardsRef.current.push(el)}>
                                <div className="e-card-content e-h-full e-bg-gradient-rose e-rounded-3xl e-shadow-[0_15px_20px_-25px_rgba(0,0,0,.35)] e-px-4 e-py-8">
                                    <div className="e-flex e-flex-col e-justify-center e-items-center e-space-y-3 e-text-center">
                                        <img className="e-w-28 e-h-20 e-drop-shadow e-object-cover e-rounded-full e-overflow-hidden" src={ai} draggable="false" />
                                        <h3 className="e-text-2xl e-font-semibold">{ReactHtmlParser(t('landing.forstudents.card4.title'))}</h3>
                                        <div className="e-text-lg e-text-gray-500">{t('landing.forstudents.card4.text')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="e-mb-40">
                <div className="e-container">
                    <div className="e-grid e-grid-cols-2 e-items-center lg:e-grid-cols-1 e-gap-10">
                        <div className="e-flex e-items-center e-justify-center">
                            <div className="e-bg-sky-50 e-w-3/4 e-h-full e-overflow-hidden e-aspect-square e-rounded-full e-flex e-items-center e-justify-center">
                                <img className="e-h-full" src={womanTablet} draggable="false" />
                            </div>
                        </div>

                        <div className="e-flex e-flex-col e-space-y-6">
                            <div className='e-flex e-flex-col e-space-y-2'>
                                <h3 className="e-text-5xl md:e-text-4xl e-font-semibold">{t('landing.forteachers.title')}</h3>
                                <div className="e-text-lg e-text-gray-500 e-font-medium">{t('landing.forteachers.subtitle')}</div>
                            </div>
                            
                            <ul className="e-grid e-grid-cols-2 sm:e-grid-cols-1 e-gap-y-3 e-gap-x-10 e-w-fit e-text-lg e-font-medium e-relative [&>li]:e-whitespace-nowrap [&>li]:e-flex [&>li]:before:e-mr-2.5 [&>li]:before:e-bg-[url(../images/checkmark.svg)] [&>li]:before:e-inline-block [&>li]:before:e-h-6 [&>li]:before:e-w-6">
                                <li>{t('landing.forteachers.advantages1')}</li>
                                <li>{t('landing.forteachers.advantages2')}</li>
                                <li>{t('landing.forteachers.advantages3')}</li>
                                <li>{t('landing.forteachers.advantages4')}</li>
                                <li>{t('landing.forteachers.advantages5')}</li>
                                <li>{t('landing.forteachers.advantages6')}</li>
                            </ul>

                            {isAuthenticated ? (
                                <>
                                    <button onClick={() =>
                                        history.push(
                                            userRoles.includes(role) ? "/dashboard/courses" : "/admin"
                                        )
                                    }
                                        className="e-btn e-btn-lg e-btn-primary e-px-10 e-w-fit sm:e-mx-auto">{userRoles.includes(role) ? t("landing.courseList") : t("landing.admin")}</button>

                                </>
                            ) : (<>
                                <button onClick={openLoginModal} className="e-btn e-btn-lg e-btn-primary e-px-10 e-w-fit sm:e-mx-auto">{t('landing.startLearning')}</button>
                            </>)}
                        </div>
                    </div>
                </div>
            </section>

            <Reviews />

            <Questions />

            <LoginModal isModalOpen={isLoginModalOpen} closeModal={closeLoginModal} kundelikId={kundelikId} />
            <VideoModal isModalOpen={isVideoModalOpen} closeModal={closeVideoModal} kundelikId={kundelikId} />
        </main>
    )
}

export default withTranslation()(WelcomePage);