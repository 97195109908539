import React, { useState } from "react";

import { withTranslation } from "react-i18next";

import axios from "axios";

const Loading = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height={24} viewBox="0 0 200 200">
    <circle fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="15" r="15" cx="40" cy="100">
      <animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate>
    </circle>
    <circle fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="15" r="15" cx="100" cy="100">
      <animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate>
    </circle>
    <circle fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="15" r="15" cx="160" cy="100">
      <animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate>
    </circle>
  </svg>
);

const CallBackForm = ({ t }) => {
        const [name, setName] = useState("");
        const [phone, setPhone] = useState("");
        const [isSubmitting, setIsSubmitting] = useState(false);
        const [lastSubmitTime, setLastSubmitTime] = useState(0);
        const [error, setError] = useState("");
        const [success, setSuccess] = useState("");
        const [honeypot, setHoneypot] = useState(""); // honeypot field

        const handleChange = (e) => {
          const input = e.target.value;
          const digits = input.replace(/\D/g, "");
          const formatted = formatPhoneNumber(digits);
          setPhone(formatted);
        };

        // Функция для форматирования номера
        const formatPhoneNumber = (number) => {
          if (number.length > 11) {
            number = number.slice(0, 11); // Ограничиваем до 10 цифр
          }

          const parts = [];
          if (number.length > 0) {
            parts.push(`+${number.slice(0, 1)}`);
          }
          if (number.length > 1) {
            parts.push(`(${number.slice(1, 4)}`);
          }
          if (number.length > 4) {
            parts.push(`) ${number.slice(4, 7)}`);
          }
          if (number.length > 7) {
            parts.push(`-${number.slice(7, 11)}`);
          }
          return parts.join("");
        };

        const handleSubmit = async (e) => {
          e.preventDefault();

          // Проверка на наличие honeypot-поля (если заполнено — спам)
          if (honeypot) return;

          // Проверка на тайм-аут в 30 секунд
          const now = Date.now();
          if (now - lastSubmitTime < 30000) {
            setError(t("landing.callback.lastSubmitTime"));
            return;
          }

          setIsSubmitting(true);
          setError("");
          setSuccess("");
          setLastSubmitTime(now); // Обновляем время последней отправки

          try {
            const response = await axios.post("/api/telegram/callback", { name, phone });
            setSuccess(t("landing.callback.success"));
          } catch (err) {
            setError(t("landing.callback.error"));
            console.error("Error:", err);
          } finally {
            setIsSubmitting(false);
          }
        };

        return (
          <form onSubmit={handleSubmit} className="e-bg-white e-shadow-[0_15px_20px_-25px_rgba(0,0,0,.35)] e-py-10 e-px-12 md:e-p-6 e-rounded-2xl e-mb-20">
            <input type="text" style={{ display: "none" }} value={honeypot} onChange={(e) => setHoneypot(e.target.value)} />
            <div className="e-flex e-flex-col e-space-y-2">
              <div className="e-font-medium e-text-xl">{t("landing.callback.title")}</div>
              <div className="e-grid e-grid-cols-3 e-gap-4 lg:e-grid-cols-2 md:e-grid-cols-1 e-items-end">
                <label className="e-flex e-flex-col">
                  <span className="e-text-sm e-text-gray-400 e-px-0.5 lg:e-hidden">{t("landing.callback.name")}</span>
                  <input type="text" value={name} required onChange={(e) => setName(e.target.value)} placeholder={t("landing.callback.yourName")} className="e-px-4 e-h-12 e-w-full e-border-2 e-rounded-lg" />
                </label>
                <label className="e-flex e-flex-col">
                  <span className="e-text-sm e-text-gray-400 e-px-0.5 lg:e-hidden">{t("landing.callback.phone")}</span>
                  <input type="text" value={phone} required onChange={handleChange} placeholder="+7 ( __ ) ___-____" className="e-px-4 e-h-12 e-w-full e-border-2 e-rounded-lg" />
                </label>
                <button type="submit" className="e-btn e-btn-secondary e-w-full lg:e-col-span-full">
                  {isSubmitting ? <Loading /> : t("landing.callback.join")}
                </button>
              </div>
              {error && <p style={{ color: "red" }}>{error}</p>}
              {success && <p style={{ color: "green" }}>{success}</p>}
            </div>
          </form>
        );
      };;

export default withTranslation()(CallBackForm);
