import React, { useEffect, useRef, useState } from "react";

import { withTranslation } from "react-i18next";

const QuoteIcon = () => (
  <svg width="40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.5 59.3">
    <path fill="currentColor" d="M31.5 7c0-3.8-3.1-7-7-7H7C3.1 0 0 3.1 0 7v18.6c0 3.8 3.1 7 7 7h10.9c1.4 0 2.6 1.2 2.6 2.6v1.2c0 6.6-5.3 11.9-11.9 11.9-1.6 0-2.9 1.3-2.9 2.9v5.2c0 1.6 1.3 2.9 2.9 2.9 12.7 0 22.9-10.3 22.9-22.9V7zm37 0c0-3.8-3.1-7-7-7H44c-3.8 0-7 3.1-7 7v18.6c0 3.8 3.1 7 7 7h10.9c1.4 0 2.6 1.2 2.6 2.6v1.2c0 6.6-5.3 11.9-11.9 11.9-1.6 0-2.9 1.3-2.9 2.9v5.2c0 1.6 1.3 2.9 2.9 2.9 12.7 0 22.9-10.3 22.9-22.9V7z" />
  </svg>
);

const StarRating = () => (
  <div className="e-flex e-items-center e-space-x-1.5 e-text-secondary">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor">
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M12.0006 18.26L4.94715 22.2082L6.52248 14.2799L0.587891 8.7918L8.61493 7.84006L12.0006 0.5L15.3862 7.84006L23.4132 8.7918L17.4787 14.2799L19.054 22.2082L12.0006 18.26Z"></path>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor">
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M12.0006 18.26L4.94715 22.2082L6.52248 14.2799L0.587891 8.7918L8.61493 7.84006L12.0006 0.5L15.3862 7.84006L23.4132 8.7918L17.4787 14.2799L19.054 22.2082L12.0006 18.26Z"></path>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor">
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M12.0006 18.26L4.94715 22.2082L6.52248 14.2799L0.587891 8.7918L8.61493 7.84006L12.0006 0.5L15.3862 7.84006L23.4132 8.7918L17.4787 14.2799L19.054 22.2082L12.0006 18.26Z"></path>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor">
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M12.0006 18.26L4.94715 22.2082L6.52248 14.2799L0.587891 8.7918L8.61493 7.84006L12.0006 0.5L15.3862 7.84006L23.4132 8.7918L17.4787 14.2799L19.054 22.2082L12.0006 18.26Z"></path>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor">
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M12.0006 18.26L4.94715 22.2082L6.52248 14.2799L0.587891 8.7918L8.61493 7.84006L12.0006 0.5L15.3862 7.84006L23.4132 8.7918L17.4787 14.2799L19.054 22.2082L12.0006 18.26Z"></path>
    </svg>
  </div>
);

const Reviews = ({ t }) => {
  const cardsRef = useRef([]);

  useEffect(() => {
    if (cardsRef.current.length) {
      // Card effects
      cardsRef.current.forEach((card) => {
        if (card) { // Проверка на null
          card.addEventListener("mousemove", handleMouseMoveCard);
          card.addEventListener("mouseleave", handleMouseLeaveCard);
        }
      });

      return () => {
        cardsRef.current.forEach((card) => {
          if (card) { // Проверка на null
            card.removeEventListener("mousemove", handleMouseMoveCard);
            card.removeEventListener("mouseleave", handleMouseLeaveCard);
          }
        });
      };
    }
  }, []);

  const handleMouseMoveCard = (e) => {
    const card = e.currentTarget;
    const rect = card.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const rotateY = (x / rect.width - 0.5) * 30;
    const rotateX = -(y / rect.height - 0.5) * 30;

    card.querySelector(".e-card-content").style.transition = "none";
    card.querySelector(".e-card-content").style.transform = `rotateY(${rotateY}deg) rotateX(${rotateX}deg)`;
  };

  const handleMouseLeaveCard = (e) => {
    const card = e.currentTarget;
    card.querySelector(".e-card-content").style.transition = "transform 0.3s ease-in-out";
    card.querySelector(".e-card-content").style.transform = "rotateY(0) rotateX(0)";
  };

  const reviews = [
    { username: t("landing.reviews.card1.username"), location: t("landing.reviews.card1.location"), review: t("landing.reviews.card1.review") },
    { username: t("landing.reviews.card2.username"), location: t("landing.reviews.card2.location"), review: t("landing.reviews.card2.review") },
    { username: t("landing.reviews.card3.username"), location: t("landing.reviews.card3.location"), review: t("landing.reviews.card3.review") },
    { username: t("landing.reviews.card4.username"), location: t("landing.reviews.card4.location"), review: t("landing.reviews.card4.review") },
    { username: t("landing.reviews.card5.username"), location: t("landing.reviews.card5.location"), review: t("landing.reviews.card5.review") },
    { username: t("landing.reviews.card6.username"), location: t("landing.reviews.card6.location"), review: t("landing.reviews.card6.review") },
  ];

  return (
    <section id="reviews" className="e-mb-20 e-py-20 e-bg-custom-gradient-primary e-graph-paper e-relative">
      <div className="e-container">
        <div className="e-flex e-flex-col e-space-y-2 e-mb-6">
          <h4 className="e-text-5xl md:e-text-4xl e-font-semibold">{t("landing.reviews.title")}</h4>
          <div className="e-text-lg e-text-gray-500 e-font-medium">{t("landing.reviews.subtitle")}</div>
        </div>
        <div className="e-columns-3 e-gap-10 e-space-y-10 xl:e-gap-6 xl:e-space-y-6 lg:e-columns-2 md:e-columns-1">
          {reviews.map((item, index) => (
            <div className="e-card" ref={(el) => cardsRef.current.push(el)}>
              <div className="e-card-content e-bg-white e-rounded-3xl e-p-7 e-shadow-xl e-shadow-gray-400/5 e-overflow-hidden e-relative">
                <div className="e-absolute e-right-4 e-top-4 e-opacity-5">
                  <QuoteIcon />
                </div>
                <div className="e-flex e-space-x-4 e-mb-4">
                  <div className="e-flex e-flex-col e-space-y-1">
                    <div className="e-text-lg e-font-semibold e-leading-none">{item.username}</div>
                    <div className="e-text-gray-500 e-text-sm">{item.location}</div>
                    <StarRating />
                  </div>
                </div>
                <div className="e-text-gray-600">{item.review}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default withTranslation()(Reviews);
