// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import axios from "axios";
import DocumentTitle from "react-document-title";
import styled from "styled-components";
import dayjs from "dayjs";
import Calendar from "react-calendar";
// assets
import { header, property } from "assets/formatUtils";
import { adminRoutes } from "assets/routes";
import { userActions } from "assets/strings";
import { formatDate, roleOptions } from "assets/utils";
import { redirect } from "utils";
// styles
import { Button, Checkbox, Container, Dropdown, Form, Icon, Image, Input, List, Loader } from "semantic-ui-react";
import "react-calendar/dist/Calendar.css";
import "./index.css";
// components
// redux
import { logout } from "store/User";

const PaymentDueDateSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 12px 8px;
  width: fit-content;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);

  & > span:first-child {
    font-weight: 600;
    text-align: center;
  }
`;

class User extends Component {
  state = {
    loading: false,
    editing: false,
    deleting: false,
    courses: [],
    user: {
      lastAction: {},
      availableCourses: [],
      courses: [],
    },
  };

  componentDidMount() {
    this.email = this.props.match.params.email;
    this.fetchData();
  }

  fetchData = async (email) => {
    const courses = await this.fetchCourses();
    const user = await this.fetchUser(email);

    this.setState({
      courses: courses,
      user: user,
    });
  };

  fetchCourses = async () => {
    this.setState({ loading: true });
    const response = await axios.get(adminRoutes.courses.getCourses()).catch((error) => {
      // logout on unauthorized error
      if (error.response.status === 401) {
        this.props.logout();
      }
      this.setState({ loading: false });
      return [];
    });
    this.setState({ loading: false });
    return response.data.message;
  };

  fetchUser = async () => {
    this.setState({ loading: true });
    const response = await axios.get(adminRoutes.users.getUser(this.email)).catch((error) => {
      // logout on unauthorized error
      if (error.response.status === 401) {
        this.props.logout();
      }
      this.setState({ loading: false });
      return {};
    });
    this.setState({ loading: false });
    return response.data.message;
  };

  handleChange = (e, data) => {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [data.name]: data.value,
      },
    }));
  };

  handleVerification = (e, data) => {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        verification: {
          ...prevState.user.verification,
          status: data.checked,
        },
      },
    }));
  };

  handlePayment = (e, data) => {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        payment: {
          ...prevState.user.payment,
          completed: data.checked,
        },
      },
    }));
  };

  handlePaymentDueDate = (value) => {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        payment: {
          ...prevState.user.payment,
          dueDate: value,
        },
      },
    }));
  };

  handleScope = (e, data) => {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        scope: data.value,
      },
    }));
  };

  handleResetCourse = (courseId) => {
    if (!this.state.user.email) return;
    const { t } = this.props;
    const r = window.confirm(t("admin.users.user.resetCourseConfirm"));
    if (r) {
      axios.post(adminRoutes.users.resetProgress(this.state.user.email, courseId)).then(
        () => {
          this.fetchData();
        },
        (error) => {
          console.error("Error:", error);
        }
      );
    }
  };

  handleAddCourse = (courseId) => {
    if (!this.state.user.email) return;
    const { t } = this.props;
    const r = window.confirm(t("admin.users.user.addAccessConfirm"));
    if (r) {
      axios.post(adminRoutes.users.addCourseToUser(this.state.user.email, courseId)).then(
        () => {
          this.fetchData();
        },
        (error) => {
          console.error("Error:", error);
        }
      );
    }
  };

  handleRemoveCourse = (courseId) => {
    if (!this.state.user.email) return;
    const { t } = this.props;
    const r = window.confirm(t("admin.users.user.removeAccessConfirm"));
    if (r) {
      axios.post(adminRoutes.users.removeCourseFromUser(this.state.user.email, courseId)).then(
        () => {
          this.fetchData();
        },
        (error) => {
          console.error("Error:", error);
        }
      );
    }
  };

  handleEdit = () => {
    const { t } = this.props;
    const r = window.confirm(t("admin.users.user.editConfirm"));
    if (r) {
      const { email, lastName, firstName, scope, verification, payment = {} } = this.state.user;

      this.setState({ editing: true });
      axios
        .post(adminRoutes.users.editUser(email), {
          lastName: lastName,
          firstName: firstName,
          scope: scope,
          verificationStatus: verification.status,
          paymentCompleted: payment.completed,
          paymentDueDate: payment.dueDate,
        })
        .then(
          () => {
            this.setState({ editing: false });
            this.fetchData();
          },
          (error) => {
            console.error("Error:", error);
            this.setState({ editing: false });
          }
        );
    }
  };

  handleDelete = () => {
    const { t } = this.props;
    const r = window.confirm(t("admin.users.user.deletteConfirm"));
    if (r) {
      this.setState({ deleting: true });
      axios.delete(adminRoutes.users.deleteUser(this.state.user.email)).then(
        () => {
          this.setState({ deleting: false });
          this.props.history.push("/admin/users");
        },
        (error) => {
          console.error("Error:", error);
          this.setState({ deleting: false });
        }
      );
    }
  };

  render() {
    const { lastName, firstName, email, lastAction, scope, verification, payment, availableCourses, courses: userCourses = [] } = this.state.user;

    const { t } = this.props;

    const name = `${lastName} ${firstName}`;
    const formattedLastAction = lastAction.action && lastAction.date ? `${t(userActions[lastAction.action])} (${formatDate(lastAction.date)})` : `${userActions["none"]}`;

    const courseItems = this.state.courses.map((course, index) => {
      const isCourseAvailable = availableCourses.includes(course._id);
      const userCourse = userCourses.find((x) => x.course._id === course._id);

      return (
        <List.Item key={`admin-user-courses-${index}`}>
          <List.Content floated="right">
            {isCourseAvailable ? (
              <Button icon negative size="small" labelPosition="right" onClick={() => this.handleRemoveCourse(course._id)}>
                <Icon name="remove" />
                {t("admin.users.user.deleteCourse")}
              </Button>
            ) : (
              <Button icon positive size="small" labelPosition="right" onClick={() => this.handleAddCourse(course._id)}>
                <Icon name="add" />
                {t("admin.users.user.addCourse")}
              </Button>
            )}
            <Button icon primary size="small" labelPosition="right" disabled={!userCourse} onClick={() => this.handleResetCourse(course._id)}>
              <Icon name="refresh" />
              {t("admin.users.user.resetCourse")}
            </Button>
          </List.Content>
          <Image avatar src={course.cover || null} />
          <List.Content>{course.name}</List.Content>
        </List.Item>
      );
    });

    return (
      <DocumentTitle title={t("admin.users.user.title")}>
        {this.state.loading ? (
          <Loader active size="large" />
        ) : (
          <Container className="admin-user-container">
            <Button basic color="blue" onClick={() => redirect(this, "/admin/users")}>
              {t("admin.classes.new.backToStudents")}
            </Button>

            {header(name, "24px", 600)}
            {property("Email (логин)", email)}
            {property(t("admin.users.user.lastAction"), `${formattedLastAction}`)}
            {this.state.user && this.state.user.class && this.state.user.class.name && property(t("admin.analytics.class"), this.state.user.class.name)}

            <Form style={{ marginBottom: "24px" }}>
              <Form.Field required control={Input} label={t("admin.users.user.lastName")} placeholder={t("admin.users.user.lastName")} name="lastName" value={lastName || ""} onChange={this.handleChange} />
              <Form.Field required control={Input} label={t("admin.users.user.firstName")} placeholder={t("admin.users.user.lastname")} name="firstName" value={firstName || ""} onChange={this.handleChange} />
              {this.props.role === "admin" && <Form.Field control={Dropdown} label={t("admin.users.user.role")} selection value={scope} options={roleOptions} onChange={this.handleScope} />}
              <Form.Field control={Checkbox} label={t("admin.users.user.verificationStatus")} checked={verification ? verification.status : false} onChange={this.handleVerification} />
              <Form.Field control={Checkbox} label={t("admin.users.user.paymentCompleted")} checked={payment ? payment.completed : false} onChange={this.handlePayment} />

              {payment && payment.completed && (
                <PaymentDueDateSelectorContainer>
                  <span>{t("admin.users.user.paymentDueDate")}</span>
                  <Calendar value={payment && payment.dueDate ? payment.dueDate : dayjs()} onChange={this.handlePaymentDueDate} />
                </PaymentDueDateSelectorContainer>
              )}
            </Form>

            {this.props.role === "admin" && (
              <>
                <Button
                  disabled={false}
                  loading={this.state.editing}
                  style={{
                    backgroundColor: "#254441",
                    color: "#fff",
                    borderRadius: "0px",
                    marginBottom: "8px",
                  }}
                  onClick={this.handleEdit}
                >
                  {t("admin.users.user.edit")}
                </Button>
                <Button
                  disabled={scope === "admin"}
                  loading={this.state.deleting}
                  style={{
                    backgroundColor: "#cc515d",
                    color: "#fff",
                    borderRadius: "0px",
                    marginBottom: "8px",
                  }}
                  onClick={this.handleDelete}
                >
                  {t("admin.users.user.delete")}
                </Button>
              </>
            )}

            {header(t("admin.users.user.studentCourses"), "20px", 600)}
            <List divided relaxed="very" verticalAlign="middle" style={{ marginBottom: "2em" }}>
              {courseItems}
            </List>
          </Container>
        )}
      </DocumentTitle>
    );
  }
}

const mapStateToProps = (state) => ({
  role: state.user.user.scope,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(User);
