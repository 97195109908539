import React, { useState, useRef } from "react";
import ReactHtmlParser from "react-html-parser";
import question from "../../../assets/images/landing/question.png";

import { withTranslation } from "react-i18next";

const Questions = ({ t }) => {
  const [openIndex, setOpenIndex] = useState(null);
  const contentRef = useRef([]);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const questions = [
    { question: t("landing.questions.question1"), answer: t("landing.questions.answer1") },
    { question: t("landing.questions.question2"), answer: t("landing.questions.answer2") },
    { question: t("landing.questions.question3"), answer: t("landing.questions.answer3") },
    { question: t("landing.questions.question4"), answer: t("landing.questions.answer4") },
    { question: t("landing.questions.question5"), answer: t("landing.questions.answer5") },
    { question: t("landing.questions.question6"), answer: t("landing.questions.answer6") },
  ];

  return (
    <section id="faq" className="e-mb-20">
      <div className="e-container">
        <div className="e-flex e-flex-col e-space-y-2">
          <h5 className="e-text-5xl md:e-text-4xl e-font-semibold">{t("landing.questions.title")}</h5>
          <div className="e-text-lg e-text-gray-500 e-font-medium">{t("landing.questions.subtitle")}</div>
        </div>
        <div className="e-flex e-space-x-10">
          <div className="e-w-2/3 lg:e-w-full">
            <div className="e-flex e-flex-col e-space-y-6 e-mt-10">
              {questions.map((item, index) => (
                <div className="e-accordion-item e-border e-rounded-xl" key={index}>
                  <button className="e-accordion-header e-w-full e-flex e-items-center e-justify-between e-p-6" onClick={() => toggleAccordion(index)}>
                    <span className="e-text-lg e-font-semibold e-text-left">{item.question}</span>
                    <svg className={`e-accordion-icon e-transition ${openIndex === index ? "e-rotate-180" : ""}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M11.9999 13.1714L16.9497 8.22168L18.3639 9.63589L11.9999 15.9999L5.63599 9.63589L7.0502 8.22168L11.9999 13.1714Z"></path>
                    </svg>
                  </button>
                  <div className={`e-accordion-content ${openIndex === index ? "e-show" : ""}`} ref={(el) => (contentRef.current[index] = el)} style={{ maxHeight: openIndex === index ? `${contentRef.current[index].scrollHeight}px` : "0px" }}>
                    <div className="e-p-6 e-pt-0 e-text-gray-500 e-space-y-3">{ReactHtmlParser(item.answer)}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="e-w-1/3 e-flex e-justify-end lg:e-hidden">
            <img className="e-w-full e-h-full e-object-contain" src={question} draggable="false" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default withTranslation()(Questions);
