import React, { useState } from "react";

import { withTranslation } from "react-i18next";

import axios from "axios";

const Loading = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height={24} viewBox="0 0 200 200">
    <circle fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="15" r="15" cx="40" cy="100">
      <animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate>
    </circle>
    <circle fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="15" r="15" cx="100" cy="100">
      <animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate>
    </circle>
    <circle fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="15" r="15" cx="160" cy="100">
      <animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate>
    </circle>
  </svg>
);

const FeedBack = ({ t }) => {
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

const handleSubmit = async (e) => {
  e.preventDefault();
  setIsSubmitting(true);
  setError("");
  setSuccess("");

  console.log("Data being sent:", { name, mail, message }); // Лог для проверки данных

  try {
    const response = await axios.post("/api/telegram/callback", { name, mail, message });
    setSuccess(t("landing.callback.success"));
  } catch (err) {
    setError(t("landing.callback.error"));
    console.error("Error:", err);
  } finally {
    setIsSubmitting(false);
  }
};

  return (
    <>
      <form onSubmit={handleSubmit} className="e-grid e-grid-cols-2 e-gap-2 xs:e-grid-cols-1">
        <input type="text" value={name} required onChange={(e) => setName(e.target.value)} placeholder={t("landing.callback.yourName")} className="e-px-4 e-h-12 e-w-full e-bg-slate-800 e-rounded-lg" />
        <input type="email" value={mail} required onChange={(e) => setMail(e.target.value)} placeholder={t("landing.footer.placeholder.input")} className="e-px-4 e-h-12 e-w-full e-bg-slate-800 e-rounded-lg" />
        <div className="e-col-span-full e-relative">
          <textarea
            value={message}
            required
            onChange={(e) => setMessage(e.target.value)} // Обновление состояния при изменении
            className="e-resize-none e-h-full e-p-4 e-w-full e-bg-slate-800 e-rounded-lg"
            placeholder={t("landing.footer.placeholder.textarea")}
            rows="3"
          ></textarea>
          <button className="e-btn e-w-12 e-btn-primary e-px-0 e-absolute e-right-2 e-bottom-2">
            {isSubmitting ? (
              <Loading />
            ) : (
              <svg className="e-relative e-top-px e-right-px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M1.94619 9.31543C1.42365 9.14125 1.41953 8.86022 1.95694 8.68108L21.0431 2.31901C21.5716 2.14285 21.8747 2.43866 21.7266 2.95694L16.2734 22.0432C16.1224 22.5716 15.8178 22.59 15.5945 22.0876L12 14L18 6.00005L10 12L1.94619 9.31543Z"></path>
              </svg>
            )}
          </button>
        </div>
        {error && (
          <p className="e-col-span-full" style={{ color: "red" }}>
            {error}
          </p>
        )}
        {success && (
          <p className="e-col-span-full" style={{ color: "green" }}>
            {success}
          </p>
        )}
      </form>
    </>
  );
};

export default withTranslation()(FeedBack);
