import React, { useEffect, useRef } from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";

const AboutModal = ({ isModalOpen, closeModal, t }) => {
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  const handleEscape = (event) => {
    if (event.key === "Escape") {
      closeModal();
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleEscape);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscape);
    };
  }, [isModalOpen]);

  return (
    <div className={`e-modal ${isModalOpen ? "e-show" : ""}`} data-modal="signup">
      <div className="e-modal_card e-max-w-3xl" ref={modalRef}>
        <div className="e-modal_header e-bg-white e-flex e-items-center e-justify-between e-px-8 e-py-5 e-border-b e-sticky e-top-0 e-z-10">
          <h2 className="e-text-xl e-font-medium">{t("landing.header.about")}</h2>
          <button
            className="e-modal_close e-btn e-btn-xs e-w-8 e-rounded-md e-px-2 e-btn-light"
            type="button"
            onClick={() => {
              closeModal();
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z" />
            </svg>
          </button>
        </div>
        <div className="e-modal_body e-px-8 e-py-7 e-overflow-auto">
          <div className="e-prose" style={{ maxWidth: "none" }}>
            {ReactHtmlParser(t("landing.aboutmodal.text"))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const WrappedAboutModal = compose(withTranslation())(AboutModal);
