// modules
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import DocumentTitle from "react-document-title";

// styles
import "./index.css";

// components
import Preloader from "./Component/Preloader";
import Header from "./Component/Header";
import WelcomePage from "./Component/WelcomePage";
import Footer from "./Component/Footer";

const Landing = ({ history, t }) => {
  const [loading, setLoading] = useState(true);

  // Регулярное выражение для поиска CSS-файла, начинающегося на "2." и заканчивающегося на ".chunk.css"
  const cssPattern = /^.*\/2\..*\.chunk\.css$/;

  // Универсальная функция для управления стилями
  const toggleCSS = (disable) => {
    document.querySelectorAll('link[rel="stylesheet"]').forEach((link) => {
      if (cssPattern.test(link.href)) {
        link.disabled = disable;
      }
    });
  };

  useEffect(() => {
    const hasVisited = localStorage.getItem("hasVisited");

    // Отключаем стиль при монтировании
    toggleCSS(true);

    if (!hasVisited) {
      setTimeout(() => {
        setLoading(false);
        localStorage.setItem("hasVisited", "true");
      }, 2000); // Задержка в 2 секунды для демонстрации
    } else {
      setLoading(false);
    }

    // Включаем стиль при размонтировании
    return () => toggleCSS(false);
  }, []);

  return (
    <DocumentTitle title={t("landing.title")}>
      <div className="landing_page">
        {loading ? (
          <Preloader />
        ) : (
          <div className="e-wrapper">
            <Header />
            <WelcomePage />
            <Footer />
          </div>
        )}
      </div>
    </DocumentTitle>
  );
};

export const WrappedLanding = withTranslation()(Landing);
