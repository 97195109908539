// modules
import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from "react-i18next";

import FeedBack from './FeedBack';
import { AboutModal, ContactsModal, TermsModal } from '../Modals';

const Logo = () => (
    <svg width="50" height="50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99.8 100">
        <path fill="#fff" d="M51.6 47.5c-2.2-.4-4.3-.6-6.2-1.2-6.3-2-9.1-8.8-6.3-14.8 2.5-5.3 8.8-6 12.4-4.1 2.1 1.1 3 2.8 2.7 5.1-.6.1-1.3.1-2 .2-2.3.3-3.9 1.8-3.9 3.8 0 2 1.7 3.8 3.9 4.2 3.4.5 6.3-1.3 7.6-4.6 2.3-6.2-1.5-13.8-8.1-16.1-4.5-1.6-8.9-1-13.1.7-8.5 3.4-13.7 9.9-15.3 18.9-2.1 12.1 1.7 22.2 11.5 29.9 1.1.9 2.4 1.5 3.5 2.3 0 .2-.1.3-.1.5-1.2-.2-2.4-.2-3.6-.5C16 67 4.1 46.8 9 28.2c2.5-9.5 8.9-16.9 17.1-22C33.3 1.7 41.2-.3 49.6 0c10.9.4 20 4.7 27 13.3.5.6.8 1.2 1.4 2.1-9.2 15.3-18.5 30.7-27.9 46.3-3.2-5.3-1.9-9.9 1.5-14.2z" />
        <path fill="#FFA501" d="M3.8 28.8C2.6 39.7 5 51.1 11 60.3c6.4 9.6 16.6 16.3 28 18.1 7.3 1.2 15 .4 21.7-2.7 8.7-4 13.6-10.9 17.3-19.4 7.3.2 14.5.4 21.7.6-.6 11.8-10 29.7-28.5 38.4-20 9.5-43.9 4.5-58.8-12.4-13-15.1-15.6-35.8-8.6-54.1zm94.8 14.8c0 5-3.9 8.9-8.8 8.8-4.6-.1-8.4-4.1-8.4-8.9 0-4.8 3.8-8.8 8.4-8.8 5 0 8.8 3.9 8.8 8.9z" />
    </svg>
);

function Footer({ t }) {
    const [isAboutModalOpen, setAboutModalOpen] = useState(false);
    const openAboutModal = () => setAboutModalOpen(true);
    const closeAboutModal = () => setAboutModalOpen(false);
    
    const [isContactsModalOpen, setContactsModalOpen] = useState(false);
    const openContactsModal = () => setContactsModalOpen(true);
    const closeContactsModal = () => setContactsModalOpen(false);
    
    const [isTermsModalOpen, setTermsModalOpen] = useState(false);
    const openTermsModal = () => setTermsModalOpen(true);
    const closeTermsModal = () => setTermsModalOpen(false);

    return (
        <>
            <AboutModal isModalOpen={isAboutModalOpen} closeModal={closeAboutModal} />
            <ContactsModal isModalOpen={isContactsModalOpen} closeModal={closeContactsModal} />
            <TermsModal isModalOpen={isTermsModalOpen} closeModal={closeTermsModal} />
            <footer className="e-bg-slate-900 e-py-6 e-text-slate-200">
                <div className="e-container">
                    <div className="e-grid e-grid-cols-5 e-gap-6 e-py-6 lg:e-grid-cols-3 sm:e-grid-cols-1">
                        <div className="e-flex e-flex-col e-space-y-3 e-col-span-2 lg:e-col-span-full">
                            <a href="/" className="e-flex e-items-center e-space-x-3">
                                <Logo />
                                <span className="e-text-secondary e-text-xl e-font-logo"> <span className="e-text-white">ekitap</span>.kz </span>
                            </a>
                            <div className="e-pr-20 e-text-slate-400 e-text-sm e-h-full">{t('landing.footer.description')}</div>
                        </div>

                        <div className="e-flex e-flex-col">
                            <input type="checkbox" className="e-peer e-hidden" id="about" checked={true} readOnly />
                            <label htmlFor="about" className="e-font-semibold e-mb-4 sm:e-mb-0 sm:peer-checked:e-mb-4">{t("landing.footer.menu.title1")}</label>
                            <ul className="e-text-sm e-flex e-flex-col e-space-y-3 sm:e-hidden sm:peer-checked:e-flex">
                                <li><button type='button' onClick={openAboutModal} className="e-transition hover:e-text-primary">{t("landing.footer.menu.link1")}</button></li>
                                <li><button type='button' onClick={openContactsModal} className="e-transition hover:e-text-primary">{t("landing.footer.menu.link2")}</button></li>
                                <li><button type='button' onClick={openTermsModal} className="e-transition hover:e-text-primary">{t("landing.footer.menu.link3")}</button></li>
                                <li><a href="#reviews" className="e-transition hover:e-text-primary">{t("landing.footer.menu.link4")}</a></li>
                                <li><a href="#faq" className="e-transition hover:e-text-primary">{t("landing.footer.menu.link5")}</a></li>
                            </ul>
                        </div>

                        <div className="e-col-span-2 sm:e-col-span-full e-flex e-flex-col">
                            <div className="e-font-semibold e-mb-4 sm:e-mb-0 sm:peer-checked:e-mb-4">
                                {t("landing.footer.menu.title2")}
                            </div>

                            <FeedBack />
                        </div>
                    </div>

                    <div className="e-flex e-border-t e-border-slate-800 e-pt-6">&copy; Copyright 2024 ekitap.kz</div>
                </div>
            </footer>
        </>
    )
}

export default withTranslation()(Footer);